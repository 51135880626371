import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { useLangFile } from 'js/context/LanguageContext';
import PrescriptionSettings from './PrescriptionSettings';
import { usePrescriptionJob } from 'js/components/Prescription/PrescriptionJobContext';
import PropTypes from 'prop-types';
import { MetaTypes, type MetaType } from '../../PrescriptionJob';
import Box from '@material-ui/core/Box';
import SelectSeasonFormControl from '../../../UI-Elements/SelectSeasonFormControl';
import {
  PRESCRIPTION_METERING,
  PRESCRIPTION_UNIT,
} from '../../../../constants/PrescriptionConstants';
import { red } from '@material-ui/core/colors';
import { SURVEY_LAYERS } from '../../../../constants/SurveyLayers';

const styles = (theme) => ({
  content: {
    overflowY: 'visible',
  },
  actions: {
    justifyContent: 'space-between',
  },
  '@media (max-width: 1024px), (max-height: 800px)': {
    root: {
      padding: theme.spacing(1),
    },
    content: {
      padding: theme.spacing(1),
    },
    title: {
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    actions: {
      padding: theme.spacing(1),
      margin: 0,
    },
  },
  warning: {
    color: red['A700'],
  },
});

const PrescriptionSettingsDialog = ({
  classes,
  open,
  onSetOpen,
  onExit,
  exitEnabled,
  disableDialog,
  initialLayerType,
}) => {
  const LangFile = useLangFile();
  const { prescriptionJob, setPrescriptionJob } = usePrescriptionJob();
  const [useManualLimeEfficiencyInput, setUseManualLimeEfficiencyInput] = useState(false);

  const updateSetting = (key) =>
    useCallback(
      (value) => {
        setPrescriptionJob({ [key]: value });
      },
      [prescriptionJob]
    );

  const updateMetatype = useCallback(
    (value) => {
      const limeInfo =
        prescriptionJob.limeInfo && prescriptionJob.limeInfo.tolerance
          ? prescriptionJob.limeInfo
          : { tolerance: 1 };

      const intervals = prescriptionJob.intervals
        ? prescriptionJob.intervals.map((interval) => ({
            ...interval,
            prescription: 0,
          }))
        : undefined;

      // Since Lime Demand has to be a pseudo layer for the appliction to handle things without a major refactor
      // We have to change the layer when doing a Lime job, and vice versa
      const metaTypeToChanges = {
        [MetaTypes.FERTILIZING]: { metaType: value, layer: initialLayerType },
        [MetaTypes.SEEDING]: { metaType: value, layer: initialLayerType },
        [MetaTypes.LIME]: { metaType: value, limeInfo, layer: SURVEY_LAYERS.RT },
        [MetaTypes.SPRAYING]: { metaType: value, layer: initialLayerType },
        [MetaTypes.SPOT_SPRAYING]: { metaType: value, intervals, layer: initialLayerType },
        [MetaTypes.LEGACY]: { metaType: value, layer: initialLayerType },
      };
      const changes = metaTypeToChanges[value];

      setPrescriptionJob(changes, !!prescriptionJob.intervals);
    },
    [prescriptionJob]
  );

  const updateUnit = useCallback(
    (value) => {
      if (prescriptionJob.metering === PRESCRIPTION_METERING.TOTAL) {
        setPrescriptionJob({ unit: value });
        return;
      }

      if (value === PRESCRIPTION_UNIT.PIECES_M2) {
        setPrescriptionJob({ unit: value, metering: PRESCRIPTION_METERING.SQUARE_METER });
        return;
      }

      setPrescriptionJob({
        unit: value,
        metering: PRESCRIPTION_METERING.HA,
      });
    },
    [prescriptionJob]
  );

  const updateLimeInfo = useCallback(
    (value) => {
      setPrescriptionJob({ limeInfo: { ...prescriptionJob.limeInfo, ...value } });
    },
    [prescriptionJob]
  );

  const [errors, setErrors] = useState({});

  const errorsIncludes = (...keysToInclude: string[]): boolean => {
    return Object.keys(errors)
      .filter((key) => {
        return keysToInclude.includes(key);
      })
      .some((key) => errors[key]);
  };

  const defaultErrorStrategy = (): boolean => {
    const hasErrors = errorsIncludes('jobName', 'maxPrescription');
    const isMissingValues = prescriptionJob.metaType === '' || prescriptionJob.unit === '';
    return hasErrors || isMissingValues;
  };

  const limeErrorStrategy = (): boolean => {
    const limeEfficiencySelector = useManualLimeEfficiencyInput
      ? 'manualLimeEfficiency'
      : 'limeSupplierEfficiency';
    const hasError = errorsIncludes('jobName', 'maxPrescription', limeEfficiencySelector);
    const isMissingValues =
      prescriptionJob.metaType === '' ||
      prescriptionJob.unit === '' ||
      !prescriptionJob.limeInfo?.tolerance ||
      !prescriptionJob.limeInfo?.efficiency; // This last one handles if efficiency is chosen from dropdown

    return hasError || isMissingValues;
  };

  // Select controls doesn't seem to play well with errors, resulting in us needing to check for values instead of errors
  const disabledStategy = {
    [MetaTypes.FERTILIZING]: () => defaultErrorStrategy(),
    [MetaTypes.SEEDING]: () => defaultErrorStrategy(),
    [MetaTypes.LIME]: () => limeErrorStrategy(),
    [MetaTypes.SPRAYING]: () => defaultErrorStrategy(),
    [MetaTypes.SPOT_SPRAYING]: () => defaultErrorStrategy(),
    [MetaTypes.LEGACY]: () => defaultErrorStrategy(),
    '': () => true,
  };

  // If there are any errors, disable the submit button
  const disabled = () => disabledStategy[prescriptionJob.metaType]();

  const handleOnClose = useCallback(() => {
    onSetOpen(false);
    // Dispatch action or such to make sure calculations are being done
  }, []);

  const handleOnError = useCallback((key, hasError) => {
    setErrors((current) => ({ ...current, [key]: hasError }));
  }, []);

  const SeasonControl = (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      style={disableDialog ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
      <span>{LangFile.PrescriptionSettingsDialog.title}</span>
      <SelectSeasonFormControl
        value={prescriptionJob.seasonId}
        onChange={updateSetting('seasonId')}
        fieldId={prescriptionJob.fieldId}
        disable={disableDialog}
      />
    </Box>
  );

  return (
    <Dialog open={open} fullWidth maxWidth={'lg'} className={classes.root}>
      <DialogTitle className={classes.title}>
        {disableDialog && (
          <div className={classes.warning}>{LangFile.PrescriptionSettingsDialog.missingField}</div>
        )}
        {!disableDialog && SeasonControl}
      </DialogTitle>
      <DialogContent className={classes.content} style={disableDialog ? { opacity: '0.4' } : {}}>
        <PrescriptionSettings
          maxPrescription={prescriptionJob.maxPrescription}
          metering={prescriptionJob.metering}
          fieldSize={prescriptionJob.fieldSize}
          unit={prescriptionJob.unit}
          jobName={prescriptionJob.jobName}
          metaType={prescriptionJob.metaType}
          limeInfo={prescriptionJob.limeInfo}
          onError={handleOnError}
          onUpdateMetering={updateSetting('metering')}
          onUpdateMaxPrescription={updateSetting('maxPrescription')}
          onUpdateJobName={updateSetting('jobName')}
          onUpdateUnit={updateUnit}
          onUpdateMetaType={updateMetatype}
          onUpdateLimeInfo={updateLimeInfo}
          isDisabled={disableDialog}
          useManualLimeEfficiencyInput={useManualLimeEfficiencyInput}
          onUpdateUseManualEfficiencyLimeInput={setUseManualLimeEfficiencyInput}
          assets={prescriptionJob.assets}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        {exitEnabled ? (
          <Button onClick={onExit} variant={'outlined'}>
            {LangFile.PrescriptionSettingsDialog.exit}
          </Button>
        ) : (
          <span />
        )}
        {!disableDialog && (
          <Button
            onClick={handleOnClose}
            color="primary"
            disabled={disabled()}
            variant={'contained'}>
            {LangFile.PrescriptionSettingsDialog.done}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

PrescriptionSettingsDialog.propTypes = {
  open: PropTypes.bool,
  exitEnabled: PropTypes.bool,
  onSetOpen: PropTypes.func,
  onExit: PropTypes.func,
  disableDialog: PropTypes.bool,
  initialLayerType: PropTypes.string,
};

export default withStyles(styles)(PrescriptionSettingsDialog);
