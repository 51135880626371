// @flow

import React, { memo, useCallback, useEffect, useState } from 'react';

import { SURVEY_LAYERS } from '../../constants/SurveyLayers';

import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { usePrescriptionJob } from 'js/components/Prescription/PrescriptionJobContext';
import PrescriptionEditor from 'js/components/Prescription/PrescriptionEditor';
import { useFarm } from '../../context/AccountContext';
import {
  calculateClassificationIntervalAreas,
  calculateClassificationIntervals,
  calculateIntervalAreas,
  calculateIntervals,
  calculateOverridesArea,
  initializeOverrides,
  calculateLimeJobIntervals,
  calculateLimePrescriptionValues,
} from './PrescriptionUtils';
import PrescriptionSettingsDialog from 'js/components/Prescription/Dialogs/PrescriptionSettingsDialog/PrescriptionSettingsDialog';
import PrescriptionAutoAdjustDialog from 'js/components/Prescription/Dialogs/PrescriptionAutoAdjustDialog/PrescriptionAutoAdjustDialog';
import { useSurveyContext, useSurveyLayerViewCapabilities } from '../../context/SurveyContext';
import { PrescriptionJob, MetaTypes, Interval } from './PrescriptionJob';
import { Action, useActionSnackbarContext } from '../ActionSnackbarHandler/ActionSnackbarHandler';
import { useLangFile } from '../../context/LanguageContext';
import { connect } from 'react-redux';
import { getReferenceValues, getLimeJobValues } from '../../reducers/SurveyReducer';
import ViewModeConstants from '../../constants/ViewModeConstants';
import { SATELLITE_LAYERS } from '../../constants/SatelliteLayers';
import { resetFutureJob, setCurrentJob, setFutureJob } from '../../reducers/PrescriptionReducer';
import MultipleActionsDialog from '../Prescription/Dialogs/MultipleActionsDialog.jsx';
import { LOWEST_INTERVAL_HIGH } from './PrescriptionJob';

const validJob = (job: PrescriptionJob) => {
  const invalidIntervals = job.intervals.filter((interval) => {
    return (
      Number.isNaN(interval.min) ||
      Number.isNaN(interval.max) ||
      !Number.isFinite(interval.min) ||
      !Number.isFinite(interval.max)
    );
  });

  return invalidIntervals.length <= 0;
};

const mapStateToProps = (store) => ({
  selectedSurveyReferenceValues: store.survey.selectedSurveyReferenceValues,
  selectedSurveyClassificationValues: store.survey.selectedSurveyClassificationValues,
});

const PrescriptionEditorContainer = ({
  dispatch,
  onExit,
  onExitSafe,
  onDownloadJob,
  selectedField,
  selectedSurveyReferenceValues,
  selectedSurveyClassificationValues,
  renderSatelliteDateHandler,
  disableSettingsDialog,
  showCurrentJob,
  onSaveJob,
  onDeleteFutureJob,
  isResumingJob,
  updatePrescriptionJob,
  onMergePrescriptionJobs,
}) => {
  const farm = useFarm();
  const LangFile = useLangFile();
  const { addAction } = useActionSnackbarContext();
  const { surveys, selectedSurvey } = useSurveyContext();
  const { prescriptionJob, setPrescriptionJob } = usePrescriptionJob();
  const layerCapabilities = useSurveyLayerViewCapabilities(
    prescriptionJob.layer,
    ViewModeConstants.PRESCRIPTION
  );

  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [showAutoAdjustDialog, setShowAutoAdjustDialog] = useState(false);
  const [classificationsEnabled, setClassificationsEnabled] = useState(false);
  const [classificationReset, setClassificationReset] = useState(false);
  const [showIntervalAdjustDialog, setShowIntervalAdjustDialog] = useState(false);
  const [initialLayerType, setInitialLayerType] = useState(prescriptionJob.layer);

  // When a new job is started or settings are modified.
  useEffect(() => {
    if (!prescriptionJob || prescriptionJob.maxPrescription) {
      return;
    }
    setShowSettingsDialog(true);
  }, [prescriptionJob]);

  // Load Values when Layer and Max Prescription is Selected and done Button is clicked
  useEffect(() => {
    if (
      !prescriptionJob.layer ||
      !prescriptionJob.maxPrescription ||
      prescriptionJob.values ||
      showSettingsDialog
    ) {
      return;
    }

    const metaTypeToValuesFetchAction = {
      [MetaTypes.FERTILIZING]: () =>
        defaultDataFecthForJob(
          farm.farmId,
          prescriptionJob.fieldId,
          prescriptionJob.date,
          prescriptionJob.layer,
          selectedSurvey,
          prescriptionJob.jobId
        ),
      [MetaTypes.SEEDING]: () =>
        defaultDataFecthForJob(
          farm.farmId,
          prescriptionJob.fieldId,
          prescriptionJob.date,
          prescriptionJob.layer,
          selectedSurvey,
          prescriptionJob.jobId
        ),
      [MetaTypes.LIME]: () =>
        fetchDataForLimeJob(
          farm.farmId,
          prescriptionJob.fieldId,
          prescriptionJob.date,
          selectedSurvey,
          prescriptionJob.jobId
        ),
      [MetaTypes.SPRAYING]: () =>
        defaultDataFecthForJob(
          farm.farmId,
          prescriptionJob.fieldId,
          prescriptionJob.date,
          prescriptionJob.layer,
          selectedSurvey,
          prescriptionJob.jobId
        ),
      [MetaTypes.SPOT_SPRAYING]: () =>
        defaultDataFecthForJob(
          farm.farmId,
          prescriptionJob.fieldId,
          prescriptionJob.date,
          prescriptionJob.layer,
          selectedSurvey,
          prescriptionJob.jobId
        ),
      [MetaTypes.LEGACY]: () =>
        defaultDataFecthForJob(
          farm.farmId,
          prescriptionJob.fieldId,
          prescriptionJob.date,
          prescriptionJob.layer,
          selectedSurvey,
          prescriptionJob.jobId
        ),
    };

    const dataFetchAction = metaTypeToValuesFetchAction[prescriptionJob.metaType];

    dataFetchAction();
  }, [prescriptionJob, farm, selectedSurvey, showSettingsDialog]);

  const defaultDataFecthForJob = (farmId, fieldId, date, layer, survey, jobId) => {
    dispatch(getReferenceValues(farmId, fieldId, date, layer, survey, jobId));
  };

  const fetchDataForLimeJob = (
    farmId: number,
    fieldId: number,
    date: Date,
    survey,
    jobId: number
  ) => {
    dispatch(getLimeJobValues(farmId, fieldId, date, survey, jobId));
  };

  useEffect(() => {
    if (
      (prescriptionJob.values && prescriptionJob.intervals) ||
      !selectedSurveyReferenceValues[prescriptionJob.layer]
    ) {
      return;
    }

    // Maybe we want to handle all special cases, but for now this will suffice
    const currentAndFutureValues =
      prescriptionJob.metaType !== MetaTypes.LIME
        ? { current: selectedSurveyReferenceValues[prescriptionJob.layer], future: null }
        : calculateLimePrescriptionValues(
            selectedSurveyReferenceValues[SURVEY_LAYERS.HUMUS],
            selectedSurveyReferenceValues[SURVEY_LAYERS.CLAY],
            selectedSurveyReferenceValues[SURVEY_LAYERS.RT],
            prescriptionJob.maxPrescription / prescriptionJob.fieldSize,
            prescriptionJob.limeInfo.tolerance,
            prescriptionJob.limeInfo.efficiency
          );

    const currentValues = currentAndFutureValues.current;
    const futureValues = currentAndFutureValues.future;

    if (currentValues == null) {
      // invalid choice
      setPrescriptionJob({ source: null, layer: null });
      return;
    }

    // Here we manually calculate the job from the ground instead of having the reducer recalculate partially
    const jobIndex = prescriptionJob.vrmSet ? prescriptionJob.vrmSet.jobIndex : 0;
    const jobWithValues: PrescriptionJob = {
      ...prescriptionJob,
      values: currentValues,
    };

    const commonIntervals = () => calculateIntervals(jobWithValues);

    const metaTypeToIntervalCalculation = {
      [MetaTypes.FERTILIZING]: {
        getIntervals: () => commonIntervals(),
      },
      [MetaTypes.SEEDING]: {
        getIntervals: () => commonIntervals(),
      },
      [MetaTypes.LIME]: {
        getIntervals: () => calculateLimeJobIntervals(jobWithValues.values),
      },
      [MetaTypes.SPRAYING]: {
        getIntervals: () => commonIntervals(),
      },
      [MetaTypes.SPOT_SPRAYING]: {
        getIntervals: () => commonIntervals(),
      },
      [MetaTypes.LEGACY]: {
        getIntervals: () => commonIntervals(),
      },
    };

    const jobWithIntervalsAndOverrides: PrescriptionJob = {
      ...jobWithValues,
      overrides: !jobWithValues.overrides
        ? initializeOverrides(jobWithValues)
        : jobWithValues.overrides,
      intervals: !jobWithValues.intervals
        ? metaTypeToIntervalCalculation[prescriptionJob.metaType].getIntervals()
        : jobWithValues.intervals,
    };

    const jobWithOverrideAreas = {
      ...jobWithIntervalsAndOverrides,
      overrideAreas: calculateOverridesArea(jobWithIntervalsAndOverrides),
    };

    const newJob: PrescriptionJob = calculateIntervalAreas(jobWithOverrideAreas);

    // Show adjusting dialog if lime prescription for lowest interval is set
    if (
      prescriptionJob.metaType === MetaTypes.LIME &&
      newJob.intervals[0].prescription > 0 &&
      !isResumingJob
    ) {
      setShowIntervalAdjustDialog(true);
    }

    if (!validJob(newJob)) {
      // this layer cannot produce a valid prescription map
      addAction(
        new Action(
          'invalid-layer',
          LangFile.PrescriptionEditorContainer.invalidValues,
          'error',
          'filled'
        )
      );
      setPrescriptionJob({ layer: null });
    }

    if (jobIndex > 0 === false) {
      setPrescriptionJob(newJob, false);
    }

    // Split out into functions and set currentJob as FutureJob if jobIndex is higher
    if (futureValues === null || (isResumingJob && jobIndex <= 0)) {
      return;
    }
    const vrmSet = prescriptionJob.vrmSet;
    const futureJobVrmSet = vrmSet != null ? vrmSet : { jobIndex: 1 };

    const futureJobWithValues: PrescriptionJob = {
      ...prescriptionJob,
      jobName: prescriptionJob.jobName + ` - ${LangFile.PrescriptionEditorContainer.future}`,
      values: futureValues,
      date: prescriptionJob.date
        ? new Date(
            prescriptionJob.date.getFullYear() + 1,
            prescriptionJob.date.getMonth(),
            prescriptionJob.date.getDate()
          )
        : new Date(),
      vrmSet: futureJobVrmSet,
    };

    const futureJobWithIntervalsAndOverrides: PrescriptionJob = {
      ...futureJobWithValues,
      overrides: !futureJobWithValues.overrides
        ? initializeOverrides(futureJobWithValues)
        : futureJobWithValues.overrides,
      intervals: calculateLimeJobIntervals(futureJobWithValues.values),
    };

    const futureJobWithOverrideAreas: PrescriptionJob = {
      ...futureJobWithIntervalsAndOverrides,
      overrideAreas: calculateOverridesArea(futureJobWithIntervalsAndOverrides),
    };

    const newFutureJob: PrescriptionJob = calculateIntervalAreas(futureJobWithOverrideAreas);

    if (jobIndex > 0) {
      setFutureJobAsCurrentJob(newFutureJob);
      return;
    }

    setFutureJobAsFutureJob(newFutureJob);
  }, [selectedSurveyReferenceValues]);

  const setFutureJobAsFutureJob = (futureJob: PrescriptionJob) => {
    dispatch(setFutureJob(futureJob));
  };

  const setFutureJobAsCurrentJob = (futureJob: PrescriptionJob) => {
    setPrescriptionJob(futureJob, false);
  };

  useEffect(() => {
    if (
      classificationReset &&
      prescriptionJob.values &&
      layerCapabilities &&
      layerCapabilities.enableCustomClassification &&
      selectedSurveyClassificationValues[prescriptionJob.layer]
    ) {
      if (classificationsEnabled) {
        let newJob = {
          ...prescriptionJob,
          intervals: [],
          classifications: selectedSurveyClassificationValues[prescriptionJob.layer],
          layerType: 'FI_DEMAND',
        };
        newJob.intervals = calculateClassificationIntervals(newJob);
        newJob = calculateClassificationIntervalAreas(newJob);
        if (validJob(newJob)) {
          setPrescriptionJob(newJob, false);
        }
      } else {
        let newJob = {
          ...prescriptionJob,
          intervals: [],
          classifications: undefined,
          layerType: undefined,
        };
        newJob.intervals = calculateIntervals(newJob);
        newJob = calculateIntervalAreas(newJob);
        if (validJob(newJob)) {
          setPrescriptionJob(newJob, false);
        }
      }
      setClassificationReset(false);
    }
  }, [classificationsEnabled, classificationReset, selectedSurveyClassificationValues]);

  const onAdjustLowInterval = useCallback((amount: number) => {
    const adjustedInterval: Interval = { ...prescriptionJob.intervals[0], prescription: amount };
    const intervals: Interval[] = [
      adjustedInterval,
      ...prescriptionJob.intervals.slice(1, prescriptionJob.intervals.length),
    ];

    const jobWithAdjustedLowInterval: PrescriptionJob = {
      ...prescriptionJob,
      intervals,
    };
    dispatch(setCurrentJob(jobWithAdjustedLowInterval, true));

    setTimeout(() => {
      setShowIntervalAdjustDialog(false);
    }, 100);
  });

  const interval =
    prescriptionJob.intervals && prescriptionJob.intervals.length > 0
      ? prescriptionJob.intervals[0]
      : null;

  const enableNdvi =
    prescriptionJob.assets != null &&
    prescriptionJob.assets[SATELLITE_LAYERS.VITALITY_NDVI] !== null &&
    prescriptionJob.assets[SATELLITE_LAYERS.VITALITY_NDVI] !== undefined;
  const enableSurveys = Boolean(
    surveys.find((survey) => survey.fieldId === prescriptionJob.fieldId)
  );

  const handleUpdatePrescriptionJob = useCallback((changes, recalculate) => {
    const layer = changes.layer;
    if (layer) {
      setInitialLayerType(layer);
    }

    updatePrescriptionJob(changes, recalculate);
  });

  return (
    <Box
      style={{ overflow: 'hidden' }}
      width={'100%'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      alignItems={'center'}>
      <PrescriptionEditor
        onDownloadJob={onDownloadJob}
        onSetShowSettingsDialog={setShowSettingsDialog}
        onSetShowAutoAdjustDialog={setShowAutoAdjustDialog}
        enableSurveys={enableSurveys}
        selectedField={selectedField}
        enableNdvi={enableNdvi}
        onExitSafe={onExitSafe}
        classificationsEnabled={classificationsEnabled}
        setClassificationsEnabled={setClassificationsEnabled}
        setClassificationsReset={setClassificationReset}
        renderSatelliteDateHandler={renderSatelliteDateHandler}
        onDeleteFutureJob={onDeleteFutureJob}
        onSaveJob={onSaveJob}
        updatePrescriptionJob={handleUpdatePrescriptionJob}
        onMergePrescriptionJobs={onMergePrescriptionJobs}
      />
      <PrescriptionSettingsDialog
        open={showSettingsDialog}
        onExit={onExit}
        exitEnabled={!(prescriptionJob && prescriptionJob.intervals)}
        onSetOpen={setShowSettingsDialog}
        disableDialog={disableSettingsDialog}
        initialLayerType={initialLayerType}
      />
      <PrescriptionAutoAdjustDialog
        open={showAutoAdjustDialog}
        onSetOpen={setShowAutoAdjustDialog}
      />
      <MultipleActionsDialog
        open={showIntervalAdjustDialog}
        title={LangFile.PrescriptionEditorContainer.limeAdjustPrescription.title}
        message={`${interval?.prescription.toFixed(2)} ${LangFile.PrescriptionEditorContainer.limeAdjustPrescription.message}`}
        cancelText={LangFile.PrescriptionEditorContainer.limeAdjustPrescription.cancel}
        onCancel={() => setShowIntervalAdjustDialog(false)}
        primaryText={LangFile.PrescriptionEditorContainer.limeAdjustPrescription.thousand}
        onPrimary={() => onAdjustLowInterval(LOWEST_INTERVAL_HIGH)}
        secondaryText={LangFile.PrescriptionEditorContainer.limeAdjustPrescription.zero}
        onSecondary={() => onAdjustLowInterval(0)}
      />
    </Box>
  );
};

PrescriptionEditorContainer.propTypes = {
  selectedField: PropTypes.object,
  onExit: PropTypes.func,
  onExitSafe: PropTypes.func,
  onDownloadJob: PropTypes.func,
  selectedSurveyReferenceValues: PropTypes.object,
  selectedSurveyClassificationValues: PropTypes.object,
  renderSatelliteDateHandler: PropTypes.func,
  disableSettingsDialog: PropTypes.bool,
  showCurrentJob: PropTypes.bool,
  onSaveJob: PropTypes.func,
  onDeleteFutureJob: PropTypes.func,
  isresumingJob: PropTypes.bool,
  updatePrescriptionJob: PropTypes.func,
  onMergePrescriptionJobs: PropTypes.func,
};

export default memo(connect(mapStateToProps)(PrescriptionEditorContainer));
