import WebAPIUtils from 'js/WebAPIUtils';
import {
  Interval,
  isSatelliteLayer,
  MetaTypes,
  PrescriptionJob,
  PrescriptionJobPostBody,
  PrescriptionJobPutBody,
} from 'js/components/Prescription/PrescriptionJob';
import { VariableRateMap, VariableRateMapUpdate } from 'js/model/prescription/VariableRateMap';
import SourceTiff from 'js/model/prescription/SourceTiff';
import {
  calculateClassificationIntervalAreas,
  calculateIntervalAreas,
  calculateOverridesArea,
} from '../components/Prescription/PrescriptionUtils';
import { Formats } from '../components/Prescription/Downloading/PrescriptionDownloader';

const GET_PRESCRIPTION_MAPS = 'fieldsense/PrescriptionReducer/GET_PRESCRIPTION_MAPS';
const GET_PRESCRIPTION_DOCUMENTATION =
  'fieldsense/PrescriptionReducer/GET_PRESCRIPTION_DOCUMENTATION';
const DELETE_PRESCRIPTION_DOCUMENTATION =
  'fieldsense/PrescriptionReducer/DELETE_PRESCRIPTION_DOCUMENTATION';
const SAVE_PRESCRIPTION_JOB_LOCALLY =
  'fieldsense/PrescriptionReducer/SAVE_PRESCRIPTION_JOB_LOCALLY';
const DELETE_PRESCRIPTION_JOBS = 'fieldsense/PrescriptionReducer/DELETE_PRESCRIPTION_JOBS';
const SET_PRESCRIPTION_JOB_SEASON_ID =
  'fieldsense/PrescriptionReducer/SET_PRESCRIPTION_JOB_SEASON_ID';
const SET_CURRENT_JOB = 'fieldsense/PrescriptionReducer/SET_CURRENT_JOB';
const RESET_CURRENT_JOB = 'fieldsense/PrescriptionReducer/RESET_CURRENT_JOB';
const SET_FUTURE_JOB = 'fieldsense/PrescriptionReducer/SET_FUTURE_JOB';
const RESET_FUTURE_JOB = 'fieldsense/PrescriptionReducer/RESET_FUTURE_JOB';
const UPDATE_FUTURE_JOB = 'fieldsense/PrescriptionReducer/UPDATE_FUTURE_JOB';

function parseMetaType(metaType) {
  if (metaType === MetaTypes.SPOT_SPRAYING) {
    return MetaTypes.SPRAYING;
  } else {
    return metaType;
  }
}

export function downloadShapefilePromise(downloadJob, farm, survey, format) {
  const { jobId, jobName, layer, legacy } = downloadJob;

  if (isSatelliteLayer(layer)) {
    if (legacy) {
      return WebAPIUtils.downloadShapefileLegacy(farm.farmId, jobId, jobName);
    } else {
      return WebAPIUtils.downloadShapefile(jobId, jobName, format);
    }
  } else {
    return WebAPIUtils.generateShapefile(downloadJob, farm, survey, format);
  }
}

export function downloadISOXMLPromise(downloadJob, farm, survey, format) {
  const { jobId, jobName, layer } = downloadJob;
  if (isSatelliteLayer(layer)) {
    if (format === Formats.KVERNELAND_ISOXML) {
      format = Formats.KVERNELAND_ISOXML.concat('_new');
    }
    return WebAPIUtils.downloadISOXML(jobId, jobName, format);
  } else {
    return WebAPIUtils.generateISOXMLfile(downloadJob, farm, survey, format);
  }
}

export const savePrescriptionJobPromise = (job: PrescriptionJob, farm): Promise => {
  return new Promise(async (resolve, reject) => {
    const payload = { ...job };

    // Exclude the "key" property from the payload
    payload.intervals = payload.intervals.map((interval: Interval) => ({
      min: interval.min,
      max: interval.max,
      allocation: interval.prescription,
    }));

    if (isSatelliteLayer(job.layer)) {
      // Use the old API

      if (payload.jobId) {
        // Update existing job

        const body = new VariableRateMapUpdate(
          payload.jobName,
          parseMetaType(payload.metaType),
          payload.maxPrescription,
          payload.totalPrescription,
          payload.unit,
          payload.intervals,
          payload.overrides,
          payload.seasonId,
          payload.fieldId
        );

        const error = await WebAPIUtils.putVariableRateMap(payload.jobId, body);

        if (error) {
          reject(error);
        } else {
          resolve({ ...job, saved: true });
        }
      } else {
        // Save new job
        const body = new VariableRateMap(
          payload.jobName,
          parseMetaType(payload.metaType),
          payload.maxPrescription,
          payload.totalPrescription,
          payload.unit,
          'sentinel2',
          [new SourceTiff(payload.date, 100)],
          payload.intervals,
          payload.overrides,
          payload.seasonId
        );

        WebAPIUtils.postVariableRateMap(payload.fieldId, body)
          .then((jobId) => {
            if (jobId !== '' && Number.isInteger(jobId)) {
              resolve({ ...job, jobId, saved: true });
            } else {
              reject(new Error(`Couldn't parse job ID: ${jobId}`));
            }
          })
          .catch((e) => {
            reject(e);
          });
      }
    } else {
      // Use the new API

      if (payload.jobId) {
        const body = new PrescriptionJobPutBody();
        body.intervals = payload.intervals;
        body.layer = payload.layer.toUpperCase();
        body.maxPrescription = payload.maxPrescription;
        body.metaType = parseMetaType(payload.metaType);
        body.name = payload.jobName;
        body.overrides = payload.overrides;
        body.totalPrescription = payload.totalPrescription;
        body.unit = payload.unit;
        body.seasonId = payload.seasonId;
        body.layerType = payload.layerType;
        body.limeInfo = payload.limeInfo ? payload.limeInfo : null;
        body.vrmSet = payload.vrmSet ? payload.vrmSet : null;

        const error = await WebAPIUtils.putSurveyPrescriptionJob(payload.jobId, body, farm);

        if (error) {
          reject(error);
        } else {
          resolve({ ...job, saved: true });
        }
      } else {
        // Save new Prescription Job
        const body = new PrescriptionJobPostBody();
        body.fieldId = payload.fieldId;
        body.intervals = payload.intervals;
        body.layer = payload.layer.toUpperCase();
        body.maxPrescription = payload.maxPrescription;
        body.metaType = parseMetaType(payload.metaType);
        body.name = payload.jobName;
        body.overrides = payload.overrides;
        body.surveyId = payload.survey.surveyId;
        body.totalPrescription = payload.totalPrescription;
        body.unit = payload.unit;
        body.seasonId = payload.seasonId;
        body.layerType = payload.layerType;
        body.limeInfo = payload.limeInfo ? payload.limeInfo : null;
        body.vrmSet = payload.vrmSet != null ? payload.vrmSet : null;

        const jobId = await WebAPIUtils.postSurveyPrescriptionJob(body, farm);

        if (jobId !== '') {
          resolve({ ...job, jobId, saved: true });
        } else {
          reject(new Error(`Couldn't parse job ID: ${jobId}`));
        }
      }
    }
  });
};

export function deletePrescriptionMapsPromise(jobs: Array<PrescriptionJob>, farm) {
  return Promise.all(
    jobs.map((job) => {
      if (job.legacy) {
        return WebAPIUtils.deleteVariableRateMapLegacy(farm.farmId, job.jobId);
      } else if (isSatelliteLayer(job.layer)) {
        return WebAPIUtils.deleteVariableRateMap(job.jobId);
      } else {
        return WebAPIUtils.deleteSurveyPrescriptionJob(job.jobId, farm.farmId);
      }
    })
  );
}

export function getPrescriptionMaps(farm, fields) {
  return {
    type: GET_PRESCRIPTION_MAPS,
    payload: WebAPIUtils.getPrescriptionMaps(farm, fields),
    meta: {
      farm,
      fields,
    },
  };
}

export function getPrescriptionDocumentation(farmId) {
  return {
    type: GET_PRESCRIPTION_DOCUMENTATION,
    payload: WebAPIUtils.getPrescriptionDocumentation(farmId),
  };
}

export function deletePrescriptionDocumentation(id) {
  return {
    type: DELETE_PRESCRIPTION_DOCUMENTATION,
    payload: WebAPIUtils.deletePrescriptionDocumentation(id),
  };
}

export function savePrescriptionJobLocally(job: PrescriptionJob) {
  return {
    type: SAVE_PRESCRIPTION_JOB_LOCALLY,
    payload: job,
  };
}

export function deletePrescriptionJobs(jobs, farm) {
  return {
    type: DELETE_PRESCRIPTION_JOBS,
    payload: deletePrescriptionMapsPromise(jobs, farm),
    meta: {
      jobs: jobs,
    },
  };
}

export function setPrescriptionJobSeasonId(farm, job: PrescriptionJob, seasonId: number) {
  const clone = PrescriptionJob.cloneJob(job);
  clone.seasonId = seasonId;

  return {
    type: SET_PRESCRIPTION_JOB_SEASON_ID,
    payload: WebAPIUtils.putPrescriptionJobSeasonid(farm, job, seasonId),
    meta: {
      job: clone,
    },
  };
}

export function setCurrentJob(jobChanges, recalculate = false) {
  return {
    type: SET_CURRENT_JOB,
    payload: jobChanges,
    meta: {
      recalculate: recalculate,
    },
  };
}

export function resetCurrentJob() {
  return {
    type: RESET_CURRENT_JOB,
    payload: null,
  };
}

export function setFutureJob(job: PrescriptionJob) {
  return {
    type: SET_FUTURE_JOB,
    payload: job,
  };
}

export function updateFutureJob(changes) {
  return {
    type: UPDATE_FUTURE_JOB,
    payload: changes,
  };
}

export function resetFutureJob() {
  return {
    type: RESET_FUTURE_JOB,
    payload: null,
  };
}

/* ============== //
||     REDUCER    ||
// ============== */

const initState = {
  maps: null,
  currentJob: null,
  documentation: null,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case GET_PRESCRIPTION_MAPS + '_FULFILLED': {
      state = { ...state, maps: action.payload };
      break;
    }

    case GET_PRESCRIPTION_DOCUMENTATION + '_FULFILLED': {
      state = { ...state, documentation: action.payload };
      break;
    }

    case SET_CURRENT_JOB: {
      let newJob: PrescriptionJob = { ...state.currentJob, ...action.payload };
      if (action.meta.recalculate) {
        if (state.currentJob?.overrides !== newJob.overrides) {
          newJob.overrideAreas = calculateOverridesArea(newJob);
        }

        if (newJob.layerType === 'FI_DEMAND') {
          newJob = calculateClassificationIntervalAreas(newJob);
        } else {
          newJob = calculateIntervalAreas(newJob);
        }
      }

      state = { ...state, currentJob: newJob };
      break;
    }

    case RESET_CURRENT_JOB: {
      state = { ...state, currentJob: null };
      break;
    }

    case SAVE_PRESCRIPTION_JOB_LOCALLY: {
      let newMaps = state.maps ? [...state.maps] : [];
      const map = action.payload;

      // If we save an existing map, remove it and push the updated map to the list
      const existing = newMaps.find((m) => m.jobId === map.jobId);
      if (existing) {
        newMaps = newMaps.filter((m) => m.jobId !== map.jobId);
      }

      newMaps = [map, ...newMaps];

      state = { ...state, maps: newMaps };
      break;
    }

    case DELETE_PRESCRIPTION_JOBS + '_FULFILLED': {
      const deleted = action.meta.jobs;

      const newMaps = state.maps
        ? [...state.maps].filter((m) => !deleted.find((d) => m.jobId === d.jobId))
        : null;

      state = { ...state, maps: newMaps };
      break;
    }

    case SET_PRESCRIPTION_JOB_SEASON_ID + '_FULFILLED': {
      const changedJob = action.meta.job;
      state = {
        ...state,
        maps: [...state.maps.filter((m) => m.jobId !== changedJob.jobId), changedJob],
      };
      break;
    }

    case SET_FUTURE_JOB: {
      state = {
        ...state,
        futureJob: action.payload,
      };
      break;
    }

    case UPDATE_FUTURE_JOB: {
      const jobWithPayloadChanges: PrescriptionJob = { ...state.futureJob, ...action.payload };
      const withRecalculatedOverrideAreas: PrescriptionJob = {
        ...jobWithPayloadChanges,
        overrideAreas:
          state.futureJob.overrides !== jobWithPayloadChanges.overrides
            ? calculateOverridesArea(jobWithPayloadChanges)
            : jobWithPayloadChanges.overrideAreas,
      };

      const updatedJob: PrescriptionJob = calculateIntervalAreas(withRecalculatedOverrideAreas);

      state = { ...state, futureJob: updatedJob };

      break;
    }

    case RESET_FUTURE_JOB: {
      state = {
        ...state,
        futureJob: null,
      };
      break;
    }

    default:
      break;
  }

  return state;
}
