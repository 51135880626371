// @flow

import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { Box, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { useLangFile } from '../../../../context/LanguageContext';
import { Table, TableBody, TableRow, TableCell, TableHead, Typography } from '@material-ui/core';

const ToleranceInfoDialog = ({ open, onClose }) => {
  const tolerantCrops: string[] = ['rye', 'grass', 'oat', 'potatoe'];
  const middleCrops: string[] = [
    'wheat',
    'trticale',
    'corn',
    'redClover',
    'whiteCloverLupin',
    'turnip',
    'rapeseed',
  ];
  const sesitiveCrops: string[] = ['snailPods', 'lucerne', 'beets', 'peaBarley'];
  const LangFile = useLangFile();
  return (
    <Fragment>
      <Dialog open={Boolean(open)} fullWidth maxWidth={'md'}>
        <DialogTitle>
          {LangFile.PrescriptionSettings.limeInfo.tolerance.infoDialog.title}
        </DialogTitle>

        <DialogContent style={{ overflow: 'hidden' }}>
          <Box mb={1}>
            {' '}
            <Typography variant="subtitle">
              {LangFile.PrescriptionSettings.limeInfo.tolerance.infoDialog.message}
            </Typography>
          </Box>

          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {LangFile.PrescriptionSettings.limeInfo.tolerance.options.tolerant}
                  </TableCell>
                  <TableCell>
                    {LangFile.PrescriptionSettings.limeInfo.tolerance.options.middle}
                  </TableCell>
                  <TableCell>
                    {LangFile.PrescriptionSettings.limeInfo.tolerance.options.sensitive}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>
                    {tolerantCrops.map((tc) => (
                      <Typography variant="body1">
                        {
                          LangFile.PrescriptionSettings.limeInfo.tolerance.infoDialog.tolerantCrops[
                            tc
                          ]
                        }
                      </Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    {middleCrops.map((mc) => (
                      <Typography variant="body1">
                        {
                          LangFile.PrescriptionSettings.limeInfo.tolerance.infoDialog.middleCrops[
                            mc
                          ]
                        }
                      </Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    {sesitiveCrops.map((sc) => (
                      <Typography variant="body1">
                        {
                          LangFile.PrescriptionSettings.limeInfo.tolerance.infoDialog
                            .sensitiveCrops[sc]
                        }
                      </Typography>
                    ))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color={'default'} variant={'outlined'}>
            {LangFile.PrescriptionSettings.limeInfo.tolerance.infoDialog.close}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default memo(ToleranceInfoDialog);

ToleranceInfoDialog.propTypes = {
  open: PropTypes.bool,
};
