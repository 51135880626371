// @flow

import { SURVEY_LAYERS } from 'js/constants/SurveyLayers';
import UUID from 'js/algorithms/UUID';
import {
  ClassificationInterval,
  getSatelliteConfig,
  Interval,
  PrescriptionJob,
} from './PrescriptionJob';
import {
  PRESCRIPTION_OVERRIDE_COLORS,
  PRESCRIPTION_UNIT,
} from 'js/constants/PrescriptionConstants';
import { getMax, getMin } from '../../helpers/NumberUtils';
import { getSurveyConfig } from '../../model/surveys/SurveyLayerConfigFactory';
import * as turf from '@turf/turf';
import type { FieldLayer } from '../../constants/FieldLayers';
import { iterateRawValues, iterateValidValues } from '../../helpers/SurveyUtils';
import { getOtherLayerConfig, OTHER_LAYERS } from '../../constants/OtherLayers';
import { SATELLITE_LAYERS } from '../../constants/SatelliteLayers';
import { getYieldLayerConfig, YIELD_LAYERS } from '../../constants/YieldLayer';
import {
  calculateOptimalReactionNumberMatrix,
  calculateLimeAmountToRaiseReactionNumberByFractionMatrix,
  calculateOptimalReactionNumberWithToleranceMatrix,
  calculateNonEffectiveLimePrescriptionMatrix,
  calculateActualLimePrescriptionMatrix,
} from './limeUtils';
import { mean } from 'mathjs';

const tinycolor = require('tinycolor2');

export const DEFAULT_INTERVALS_COUNT = 4;
export const DEFAULT_MAX_PRESCRIPTION = 150;
export const SQUARE_METER_COVERSION_VALUE = 10000;

export function initializeOverrides(prescriptionJob: PrescriptionJob) {
  let existing;

  if (Array.isArray(prescriptionJob.overrides) && Array.isArray(prescriptionJob.overrides[0])) {
    existing = prescriptionJob.overrides;
  }

  const values = prescriptionJob.values;
  const height = values.length;
  const width = values[0].length;

  if (existing) {
    // If the overrides array exists we must pad the array with missing -1's, since it's stored as a SparseArray in the DB
    // That means, that all -1's after the last valid override value is pruned on the backend.
    // We need to put them back to ensure the dimensions of Overrides and Values match.

    for (let y = 0; y < height; y++) {
      const row = existing[y];

      if (row) {
        // If the row exists, we need to find out if the dimensions match
        for (let x = 0; x < width; x++) {
          const diff = width - row.length;

          if (diff > 0) {
            // The dimensions do not match, so we pad with -1's
            for (let d = 0; d < diff; d++) {
              row.push(-1);
            }
          }
        }
      } else {
        // push an entire new row of -1's
        existing.push(Array(width).fill(-1));
      }
    }
  } else {
    return Array(height).fill(Array(width).fill(-1));
  }

  return existing;
}

export function calculateOverridesArea(prescriptionJob) {
  const { overrides, fieldSize, values, overrideAreas, layerType } = prescriptionJob;

  // This is the result object.
  const newAreas = { ...overrideAreas };

  // Calculate the area (hectares) per pixel.
  const pixelCount =
    layerType === 'FI_DEMAND'
      ? values.flatten().filter((v) => v !== null).length
      : values.flatten().filter((v) => v > 0).length;
  const areaPerPixel = fieldSize / pixelCount;

  // Flatten and separate exclusions from overrides and exclude non-value (-1) pixels from both.
  const flattened = overrides.flatten();
  const flatOverrides = flattened.filter((val) => val > 0);
  const flatExclusions = flattened.filter((val) => val === 0);

  // Define the exclusion area as black for all values equal to 0.
  const exclusionArea = {
    area: flatExclusions.length * areaPerPixel,
    color: '#000',
    value: 0,
  };

  // Reset the areas counts of the existing overridden areas.
  Object.keys(newAreas).forEach((val) => {
    newAreas[val].area = 0;
  });

  // Overwrite the exclusion area
  newAreas[0] = exclusionArea;

  // Iterate all overridden values and either append the areaPerPixel to the existing area og start a new area.
  // When appending to a new area, we reuse the already set color to avoid areas changing color.
  // When starting a new area, we look for the next valid (not used) color in the default colors.
  flatOverrides.forEach((val) => {
    const existing = newAreas[val];
    if (existing) {
      existing.area += areaPerPixel;
    } else {
      newAreas[val] = {
        area: areaPerPixel,
        color: getNextOverrideColor(newAreas),
        value: val,
      };
    }
  });

  // If any areas do not have an area above 0 it means it has been deleted using the eraser, overridden with another value, or deleted from the list.
  // Thus, we delete it.
  Object.keys(newAreas).forEach((val) => {
    if (newAreas[val].area === 0) {
      delete newAreas[val];
    }
  });

  // Return the new overrideAreas.
  return newAreas || {};
}

export function calculateIntervals(prescriptionJob: PrescriptionJob) {
  let intervalsCount = prescriptionJob.intervals
    ? prescriptionJob.intervals.length
    : DEFAULT_INTERVALS_COUNT;
  if (intervalsCount === 0) {
    intervalsCount = DEFAULT_INTERVALS_COUNT;
  }

  const flatValues = [].concat(...prescriptionJob.values).filter((val) => val !== null && val > 0);

  let valuesMin, valuesMax;

  valuesMax = getMax(flatValues);
  valuesMin = getMin(flatValues);

  const span = valuesMax - valuesMin;

  let intervalWidth = 0;
  if (span === 0) {
    intervalsCount = 1;
  } else {
    intervalWidth = span / intervalsCount;
  }

  const intervals = [];

  for (let i = 0; i < intervalsCount; i++) {
    let min = Math.max(0, valuesMin);

    if (i > 0) {
      min = intervals[i - 1].max;
    }

    const max = Math.min(min + intervalWidth, valuesMax);

    let prescription;
    if (prescriptionJob.metaType === 'SPOT_SPRAYING') {
      prescription = 0;
    } else {
      if (prescriptionJob.unit === PRESCRIPTION_UNIT.PIECES_M2) {
        prescription =
          prescriptionJob.maxPrescription /
          (prescriptionJob.fieldSize * SQUARE_METER_COVERSION_VALUE);
      } else {
        prescription = prescriptionJob.maxPrescription / prescriptionJob.fieldSize;
      }
    }

    intervals.push(new Interval(min, max, prescription));
  }

  return intervals;
}

export function calculateClassificationIntervals(prescriptionJob: PrescriptionJob) {
  const intervals = [];
  const fineValues = prescriptionJob.classifications['mappings']['FINE'];
  const values = prescriptionJob.classifications['values'];
  const flatValues = []
    .concat(...values)
    .filter((val, i, a) => val !== null && a.indexOf(val) === i)
    .sort((a, b) => {
      return a - b;
    });

  let prescription;
  if (prescriptionJob.metaType === 'SPOT_SPRAYING') {
    prescription = 0;
  } else {
    prescription = prescriptionJob.maxPrescription / prescriptionJob.fieldSize;
  }

  flatValues.forEach((number, idx) => {
    const classification = Object.keys(fineValues).find((key) => fineValues[key].includes(number));
    intervals.push(
      new ClassificationInterval(number - 0.5, number + 0.5, prescription, classification)
    );
  });
  return intervals;
}

export function calculateMergedIntervals(primaryIndex, secondaryIndex, option, intervalsInput) {
  let intervals = [...intervalsInput];
  let left, right;

  // Figure our the position of the intervals relative to each other
  if (primaryIndex < secondaryIndex) {
    left = primaryIndex;
    right = secondaryIndex;
  } else {
    left = secondaryIndex;
    right = primaryIndex;
  }

  const leftInterval = intervals[left];
  const rightInterval = intervals[right];

  let prescription;

  if (option === 0) {
    // Use left prescription
    prescription = leftInterval.prescription;
  } else if (option === 1) {
    // Average prescriptions
    prescription = (leftInterval.prescription + rightInterval.prescription) / 2;
  } else {
    // Use right prescription
    prescription = rightInterval.prescription;
  }

  // Create the merged interval
  const mergedInterval = new Interval(leftInterval.min, rightInterval.max, prescription);

  // Replace the 2 indices, starting at the primaryIndex, with the mergedInterval
  // Note: splice mutates the original array and returns the deleted elements
  intervals.splice(left, 2, mergedInterval);

  intervals = intervals.map((interval) => {
    interval.key = UUID.uuidv4(); // Force unique keys for intervals (IE11)
    return interval;
  });

  // Return the (now mutated) array of intervals
  return intervals;
}

export function calculateSplitIntervals(splitIndex, intervals) {
  let newIntervals = [...intervals];

  const interval = newIntervals[splitIndex];

  const prescription = interval.prescription;
  const middle = interval.min + (interval.max - interval.min) / 2;

  // Create the split interval
  const leftInterval = new Interval(interval.min, middle, prescription);
  const rightInterval = new Interval(middle, interval.max, prescription);

  // Replace the 2 indices, starting at the primaryIndex, with the mergedInterval
  // Note: splice mutates the original array and returns the deleted elements
  newIntervals.splice(splitIndex, 1, leftInterval, rightInterval);
  newIntervals = newIntervals.map((interval) => {
    interval.key = UUID.uuidv4(); // Force unique keys for intervals (IE11)
    return interval;
  });

  // Return the (now mutated) array of intervals
  return newIntervals;
}

function getNextOverrideColor(newAreas) {
  const existingColors = Object.keys(newAreas).map((key) => newAreas[key].color);
  let color = PRESCRIPTION_OVERRIDE_COLORS.find((c) => existingColors.indexOf(c) === -1);

  if (!color) {
    color = tinycolor.random().toHexString();
  }

  return color;
}

export function calculateMaxAllocationPerField(currentMap) {
  const { field, allocationMax } = currentMap;
  const result = field.size * allocationMax;
  return Number(Number(result).toFixed(2));
}

// Don't worry..! The code below is primarily logging for debugging purposes. Read the comments to get a "guided tour".
export function calculatePrescriptionLimitAdjustedIntervals(prescriptionJob: PrescriptionJob) {
  const { intervals, maxPrescription, totalPrescription, overrideAreas } = prescriptionJob;
  const log = false;

  // Ensure immutability
  const newIntervals = [...intervals];

  let overridesPrescription = 0;

  if (overrideAreas) {
    overridesPrescription = Object.keys(overrideAreas).reduce(
      (acc, cur) => acc + cur * overrideAreas[cur].area,
      0
    );
  }

  const total = totalPrescription - overridesPrescription;
  const max = maxPrescription - overridesPrescription;

  log && console.log('Raw Intervals');
  log &&
    newIntervals.forEach((interval, idx) =>
      console.log('[' + idx + '] Prescription: ' + interval.prescription + ' kg')
    );

  // Calculate the expected ratios (from the user's perspective) according to the adjustment of the Draggable Intervals.
  let expectedRatios;
  if (prescriptionJob.unit === PRESCRIPTION_UNIT.PIECES_M2) {
    expectedRatios = newIntervals.map(
      (interval) =>
        (interval.prescription * (interval.areaFinal * SQUARE_METER_COVERSION_VALUE)) / total
    );
  } else {
    expectedRatios = newIntervals.map(
      (interval) => (interval.prescription * interval.areaFinal) / total
    );
  }
  log && console.log('Expected Ratios');
  log &&
    expectedRatios.forEach((ratio, idx) =>
      console.log('[' + idx + '] Ratio: ' + ratio * 100 + '%')
    );

  // Calculate the expected total ratio (should be very, very close to 100%) - Used for error calculation in the end
  const expectedTotal = expectedRatios.reduce((acc, ratio) => acc + ratio * 100, 0);

  log && console.log('Expected Total: ' + expectedTotal + ' %');

  // Calculate the actual ratios that the re-allocations represent relative to the Prescription Limit (as set in the Settings)
  let actualRatios;
  if (prescriptionJob.unit === PRESCRIPTION_UNIT.PIECES_M2) {
    actualRatios = newIntervals.map(
      (interval) =>
        (interval.prescription * (interval.areaFinal * SQUARE_METER_COVERSION_VALUE)) / max
    );
  } else {
    actualRatios = newIntervals.map(
      (interval) => (interval.prescription * interval.areaFinal) / max
    );
  }

  log && console.log('Actual Ratios');
  log &&
    actualRatios.forEach((ratio, idx) => console.log('[' + idx + '] Ratio: ' + ratio * 100 + '%'));

  // Calculate the differences between the actual and expected ratios
  const differences = actualRatios.map((ratio, idx) => ratio - expectedRatios[idx]);

  log && console.log('Differences');
  log &&
    differences.forEach((diff, idx) =>
      console.log('[' + idx + '] Difference: ' + diff * 100 + '%')
    );

  // Map the differences to an absolute adjustment for each interval
  const adjustments = differences.map((diff) => {
    // Negative difference means we have to add more to this interval
    // Positive difference means we have to remove from this interval
    // Thus, we invert the difference when talking about adjustments.
    return diff * max * -1;
  });

  log && console.log('Adjustments');
  log &&
    adjustments.forEach((adj, idx) =>
      console.log('[' + idx + '] Adjustment: ' + (adj > 0 ? '+ ' : '- ') + adj + ' kg')
    );

  // Apply the absolute adjustment on each interval
  const adjustedIntervals = newIntervals.map((interval, idx) => {
    log && console.log(interval.prescription, adjustments[idx], interval.areaFinal);

    if (interval.areaFinal > 0) {
      if (prescriptionJob.unit === PRESCRIPTION_UNIT.PIECES_M2) {
        interval.prescription =
          interval.prescription +
          Number(adjustments[idx]) / (interval.areaFinal * SQUARE_METER_COVERSION_VALUE);
      } else {
        interval.prescription =
          interval.prescription + Number(adjustments[idx]) / interval.areaFinal;
      }
    }

    return interval;
  });

  log && console.log('Adjusted Intervals');
  log &&
    adjustedIntervals.forEach((interval, idx) =>
      console.log('[' + idx + '] Adjusted Prescription: ' + interval.prescription + ' kg')
    );

  // Calculate the adjusted ratios for error calculation
  let adjustedRatios;
  if (prescriptionJob.unit === PRESCRIPTION_UNIT.PIECES_M2) {
    adjustedRatios = adjustedIntervals.map(
      (interval, idx) =>
        (interval.prescription * (interval.areaFinal * SQUARE_METER_COVERSION_VALUE)) / max
    );
  } else {
    adjustedRatios = adjustedIntervals.map(
      (interval, idx) => (interval.prescription * interval.areaFinal) / max
    );
  }

  log && console.log('Adjusted Ratios');
  log &&
    adjustedRatios.forEach((ratio, idx) =>
      console.log('[' + idx + '] Ratio: ' + ratio * 100 + '%')
    );

  // Calculate the new total sum of ratios for error calculation
  const adjustedTotal = adjustedRatios.reduce((acc, ratio) => acc + ratio * 100, 0);

  log && console.log('Adjusted Total: ' + adjustedTotal + ' %');

  // Calculate the difference between the initial sum of ratios and the final sum of ratios
  const adjustmentError = Number(Number(expectedTotal - adjustedTotal).toFixed(1));

  log && console.log('Adjustment Error: ' + adjustmentError * 100 + ' %');

  return { adjustedIntervals: adjustedIntervals, adjustmentError: adjustmentError };
}

export const getLayerConfig = (layer: FieldLayer) => {
  if (!layer) {
    return null;
  }

  if (Object.keys(SURVEY_LAYERS).includes(layer.toUpperCase())) {
    return getSurveyConfig(layer.toUpperCase());
  }
  if (Object.keys(SATELLITE_LAYERS).includes(layer)) {
    return getSatelliteConfig(layer);
  }
  if (Object.keys(OTHER_LAYERS).includes(layer)) {
    return getOtherLayerConfig(layer);
  }
  if (Object.keys(YIELD_LAYERS).includes(layer.toUpperCase())) {
    return getYieldLayerConfig(layer);
  }
};

export const getBufferPolygons = (field, bufferMeters) => {
  const p = turf.polygon(field.polygon.coordinates);
  const buffered = turf.buffer(p, -bufferMeters / 1000, { units: 'kilometers', steps: 64 });
  const result = [];

  if (buffered && buffered.geometry) {
    buffered.geometry.coordinates.map((entry, idx) => {
      if (Array.isArray(entry[0]) && Array.isArray(entry[0][0])) {
        result.push(...entry);
      } else {
        result.push(entry);
      }
    });
  }

  return result;
};

export const calculateIntervalAreas = (prescriptionJob: PrescriptionJob) => {
  const { values, intervals, fieldSize, overrides, overrideAreas, layerType } = prescriptionJob;

  // Make sure the cells property is reset.
  const newIntervals = [...intervals.map((interval) => ({ ...interval, cells: [] }))];
  // Find out which cells (x-y coordinates in the values array) are covered by which intervals.
  iterateValidValues(
    values,
    (x, y, value) => {
      const interval = newIntervals.find((item, idx, arr) => {
        if (idx === arr.length - 1) return true;
        return item.min <= value && value < item.max;
      });
      if (interval) {
        interval.cells = interval.cells || [];
        interval.cells.push({ x, y });
      }
    },
    layerType === 'FI_DEMAND'
  );

  // Summarize the total count of value cells
  const totalCells = newIntervals.reduce((acc, cur) => acc + cur.cells.length, 0);

  // Calculate the areas of each interval based on the covered cells and the total amount of cells.
  // Also calculate the total prescription of each interval based on the interval's final area, where the overridden area has been subtracted.
  newIntervals.forEach((interval) => {
    interval.areaRaw = (interval.cells.length / totalCells) * fieldSize;
    interval.areaOverridden =
      (interval.cells.filter((cell) => {
        const row = overrides[cell.y];
        if (Array.isArray(row)) {
          const override = row[cell.x];
          if (override !== null && override > -1) {
            return true;
          }
        }
        return false;
      }).length /
        totalCells) *
      fieldSize;
    interval.areaFinal = interval.areaRaw - interval.areaOverridden;
    if (prescriptionJob.unit === PRESCRIPTION_UNIT.PIECES_M2) {
      interval.totalPrescription =
        interval.areaFinal * SQUARE_METER_COVERSION_VALUE * interval.prescription;
    } else {
      interval.totalPrescription = interval.areaFinal * interval.prescription;
    }
  });

  // Save the new intervals
  prescriptionJob.intervals = newIntervals;
  // Summarize the total prescription for the entire field.
  prescriptionJob.totalPrescription = newIntervals.reduce(
    (acc, cur) => acc + cur.totalPrescription,
    0
  );

  // Include overrides in the summation.
  prescriptionJob.totalPrescription += Object.keys(overrideAreas).reduce((acc, key) => {
    const { area, value } = overrideAreas[key];
    return acc + area * value;
  }, 0);
  return prescriptionJob;
};

export const calculateClassificationIntervalAreas = (prescriptionJob: PrescriptionJob) => {
  const { intervals, fieldSize, overrides, overrideAreas } = prescriptionJob;

  // Make sure the cells property is reset.
  const newIntervals = [...intervals.map((interval) => ({ ...interval, cells: [] }))];
  const classificationValues = prescriptionJob.classifications['values'];
  const classificationFineValues = prescriptionJob.classifications['mappings']['FINE'];
  // Find out which cells (x-y coordinates in the values array) are covered by which intervals.
  iterateRawValues(classificationValues, (x, y, value) => {
    const interval = newIntervals.find((item, idx, arr) => {
      if (idx === arr.length - 1) return true;
      const classification = Object.keys(classificationFineValues).find((key) =>
        classificationFineValues[key].includes(value)
      );
      return item.classification === classification;
    });

    if (interval) {
      interval.cells = interval.cells || [];
      interval.cells.push({ x, y });
    }
  });

  // Summarize the total count of value cells
  const totalCells = newIntervals.reduce((acc, cur) => acc + cur.cells.length, 0);

  // Calculate the areas of each interval based on the covered cells and the total amount of cells.
  // Also calculate the total prescription of each interval based on the interval's final area, where the overridden area has been subtracted.
  newIntervals.forEach((interval) => {
    interval.areaRaw = (interval.cells.length / totalCells) * fieldSize;
    interval.areaOverridden =
      (interval.cells.filter((cell) => {
        const row = overrides[cell.y];
        if (Array.isArray(row)) {
          const override = row[cell.x];
          if (override !== null && override > -1) {
            return true;
          }
        }
        return false;
      }).length /
        totalCells) *
      fieldSize;
    interval.areaFinal = interval.areaRaw - interval.areaOverridden;
    if (prescriptionJob.unit === PRESCRIPTION_UNIT.PIECES_M2) {
      interval.totalPrescription =
        interval.areaFinal * SQUARE_METER_COVERSION_VALUE * interval.prescription;
    } else {
      interval.totalPrescription = interval.areaFinal * interval.prescription;
    }
  });

  // Save the new intervals
  prescriptionJob.intervals = newIntervals;
  // Summarize the total prescription for the entire field.
  prescriptionJob.totalPrescription = newIntervals.reduce(
    (acc, cur) => acc + cur.totalPrescription,
    0
  );
  // Include overrides in the summation.
  prescriptionJob.totalPrescription += Object.keys(overrideAreas).reduce((acc, key) => {
    const { area, value } = overrideAreas[key];
    return acc + area * value;
  }, 0);
  return prescriptionJob;
};

export const squareFromLatLng = (latLng, sizeMeters) => {
  const sizeKilometers = sizeMeters / 1000;

  const center = turf.point([latLng.lng, latLng.lat]);
  let nw = turf.transformTranslate(turf.clone(center), sizeKilometers / 2, 0);
  nw = turf.transformTranslate(nw, sizeKilometers / 2, -90);
  const ne = turf.transformTranslate(turf.clone(nw), sizeKilometers, 90);
  const se = turf.transformTranslate(turf.clone(ne), sizeKilometers, 180);
  const sw = turf.transformTranslate(turf.clone(se), sizeKilometers, -90);
  const box = turf.bbox(turf.featureCollection([nw, ne, se, sw]));

  return turf.bboxPolygon(box);
};

export const calculateValueStepSize = (percentage, prescriptionJob) => {
  if (prescriptionJob.unit === PRESCRIPTION_UNIT.PIECES_M2) {
    return (
      (prescriptionJob.maxPrescription /
        (prescriptionJob.fieldSize * SQUARE_METER_COVERSION_VALUE)) *
      percentage
    );
  }
  return (prescriptionJob.maxPrescription / prescriptionJob.fieldSize) * percentage;
};

export const getLimeValuesAndIntervals = (
  humusValues: number[][],
  clayValues: number[][],
  reactionNumbers: number[][],
  maxPrescription: number,
  cropTolerance: number,
  limeEfficiency: number
): { values: number[][]; intervals: Interval[] } => {
  const values = calculateLimePrescriptionValues(
    humusValues,
    clayValues,
    reactionNumbers,
    maxPrescription,
    cropTolerance,
    limeEfficiency
  );

  return {
    values,
    intervals: calculateLimeJobIntervals(values),
  };
};

export const calculateLimePrescriptionValues: number[][] = (
  humusValues: number[][],
  clayValues: number[][],
  reactionNumbers: number[][],
  maxPrescription: number,
  cropTolerance: number,
  limeEfficiency: number
): { current: number[][]; future: number[][] } => {
  const optimalRT = calculateOptimalReactionNumberMatrix(humusValues, clayValues);
  const amountLimeToRaiseRTByFraction = calculateLimeAmountToRaiseReactionNumberByFractionMatrix(
    humusValues,
    clayValues
  );
  const optimalReactionNumberWithTolerance = calculateOptimalReactionNumberWithToleranceMatrix(
    optimalRT,
    cropTolerance
  );
  const nonEffectiveAmountLime = calculateNonEffectiveLimePrescriptionMatrix(
    optimalReactionNumberWithTolerance,
    amountLimeToRaiseRTByFraction,
    reactionNumbers
  );
  const currentAndFuturePrescriptionAmount = calculateActualLimePrescriptionMatrix(
    nonEffectiveAmountLime,
    limeEfficiency / 100,
    maxPrescription
  );

  return {
    current: currentAndFuturePrescriptionAmount.current._data,
    future: currentAndFuturePrescriptionAmount.future
      ? currentAndFuturePrescriptionAmount.future._data
      : null,
  };
};

// Higher
export const calculateLimeJobIntervals: Interval[] = (limePrescriptionValues: number[][]) => {
  const zoneA = createIntervalWithValuesBetween(limePrescriptionValues, 0, 999);
  const zoneB = createIntervalWithValuesBetween(limePrescriptionValues, 1000, 1999);
  const zoneC = createIntervalWithValuesBetween(limePrescriptionValues, 2000, 2999);
  const zoneD = createIntervalWithValuesBetween(limePrescriptionValues, 3000, 3999);
  const zoneE = createIntervalWithValuesBetween(limePrescriptionValues, 4000, 4999);
  const zoneF = createIntervalWithValuesBetween(limePrescriptionValues, 5000, 6000);

  return [zoneA, zoneB, zoneC, zoneD, zoneE, zoneF];
};

const createIntervalWithValuesBetween = (
  values: (number | null)[][],
  min: number,
  max: number
): Interval => {
  const valuesBetween = getValuesBetween(values, min, max);
  return new Interval(
    min,
    max,
    valuesBetween && valuesBetween.length > 0 ? mean(valuesBetween) : 0
  );
};

const getValuesBetween = (values: (number | null)[][], min: number, max: number): number[] => {
  return [].concat(...values).filter((element) => {
    return element !== null && element >= min && element <= max;
  });
};
